.moco-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.moco-modal-content {
  background: white;
  padding: 20px;
  border-radius: 4px;
  min-width: 30vw;
  max-width: 90vw;
  max-height: 90vh;
  /* width: 100%; */
  position: relative;
  overflow-y: scroll;
}

.moco-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
