.image-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.image-wrapper {
  border: 2px solid #140901;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.image-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.left-container {
  position: absolute;
  left: 16px;
  top: 50% + 25px;
  bottom: 50%;
  cursor: pointer;
}
.right-container {
  position: absolute;
  right: 16px;
  top: 50% + 25px;
  bottom: 50%;
  cursor: pointer;
}

.tools-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #140901;
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
}

.hermes-navbar-container {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 50vw;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-bottom: 2px solid gray;
}

.hermes-navbar-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.hermes-navbar {
  display: flex;
}

.hermes-navbar-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.hermes-navbar-item {
  display: inline-block;
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;
}

.hermes-navbar-item:hover {
  background-color: #ddd;
}
