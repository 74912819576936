@tailwind base;
@tailwind components;
@tailwind utilities;

.navStyle {
  /* background-image: url(../public/images/background.png); */
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 0.5px;
  /* border-color: #feffff; */
  /* border-style: solid; */

  position: fixed;
  /* top: 0; */
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* overflow: hidden; */
}

.navbar-ul {
  /* background-color: red; */
  font-weight: 200;
  display: flex;
  flex-direction: row-reverse;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 0.9;
}

.navbar-ul-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 7.5vh;
  height: 100vh;
  width: 100%;
  /* background-color: red; */
  background-color: #feffff;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.navbar-li {
  display: inline;
  font-size: 24px;
  padding: 0 2em 0 2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.navbar-li-mobile {
  display: inline;
  font-size: 36px;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
  border-bottom: 1px solid gray;
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.navbar-li-mobile.active {
  display: block;
  opacity: 1;
}

.bannerStyle {
  /* background-image: url(../public/images/background.png); */
  background-size: cover;
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: right;
}

.textBoxStyle {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.push-up {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.waitlist-container {
  background-image: url(../public/images/annypsi_phone.png);
  padding-top: 83%;
  height: 500px;
  width: "100%";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.waitlist-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4); /* Adjust opacity here */
}
.waitlist-textinput {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-style {
  aspect-ratio: 514/656;
}

.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.burger-line {
  width: 100%;
  height: 3px;
  background-color: #000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.open .burger-line:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.open .burger-line:nth-child(2) {
  opacity: 0;
}

.open .burger-line:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}
.radio-container {
  display: flex;
  flex-direction: column;
}

.radio-label {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;

  cursor: pointer;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.radio-label input {
  /* display: none; */
  position: absolute;
  left: -9999px;
}

.radio-custom {
  position: absolute;
  transform: translateY(2.5px);
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /* background-color: #ff914d; */
  background-color: #ff914d;
  border-radius: 10%;
}

.radio-label input:checked ~ .radio-custom:after {
  content: "";
  position: absolute;
  display: block;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
}

.moco-title-container {
  background-image: url(../public/images/mocomap_mobile_0_5.png);
  height: 500px;
  width: 100vw;
  background-size: cover;
  background-position: center; /* Center the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  object-fit: cover;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

.marquee .coupon {
  height: 100px;
  width: 300px;
  background-color: #ffa33e;
  display: grid;
  place-items: center;
  /* width: var(--size); */
  /* fill: var(--color-text); */
  /* background: var(--color-bg-accent); */
  /* aspect-ratio: 16/9; */
  padding: calc(var(--size) / 10);
  border-radius: 4px;
}

.marquee--vertical .coupon {
  aspect-ratio: 1;
  width: calc(var(--size) / 1.5);
  padding: calc(var(--size) / 6);
}

/* Parent wrapper */
.wrapper {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ecdcc0;
  --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 120s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

.wrapper--vertical {
  flex-direction: row;
  height: 100vh;
}

.coupon-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  max-height: 1.2em;
}

.business-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  max-height: 1.2em;
}

.coupon-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  max-height: 2.4em;
}

.coupon-business-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2em;
  max-height: 1.2em;
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.custom-checkbox {
  border-radius: 0.25rem; /* Adjust the border radius */
}

.business-contact {
  flex: 1;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideInFade {
  0% {
    opacity: 0;
    display: none;
    visibility: hidden;
    transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    display: block;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes slideOutFade {
  0% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    display: none;
    transform: translateY(-25%);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25%);
  }
}

@media (min-width: 1280px) {
  .waitlist-container {
    background-image: url(../public/images/annypsi_laptop.png);
    padding-top: 43.2%; /* (img-height / img-width * container-width) */
    height: 0px;
  }
  .moco-title-container {
    background-image: url(../public/images/mocomap_laptop_0_5.png);
    height: 500px;
  }
}

@media (min-width: 640px) {
  .open .burger-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .open .burger-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
