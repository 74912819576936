@font-face {
  font-family: "Mitr";
  src: url("../public/Mitr/Mitr-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr Bold";
  src: url("../public/Mitr/Mitr-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr Regular";
  src: url("../public/Mitr/Mitr-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr SemiBold";
  src: url("../public/Mitr/Mitr-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr Light";
  src: url("../public/Mitr/Mitr-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr ExtraLight";
  src: url("../public/Mitr/Mitr-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Mitr Bold";
  src: url("../public/Mitr/Mitr-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Anta";
  src: url("../public/Anta/Anta-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Taviraj Thin";
  src: url("../public/Taviraj/Taviraj-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Taviraj";
  src: url("../public/Taviraj/Taviraj-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Taviraj Light";
  src: url("../public/Taviraj/Taviraj-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Taviraj ExtraLight";
  src: url("../public/Taviraj/Taviraj-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Taviraj Bold";
  src: url("../public/Taviraj/Taviraj-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost Bold";
  src: url("../public/Jost/static/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost";
  src: url("../public/Jost/static/Jost-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Jost Light";
  src: url("../public/Jost/static/Jost-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Jost ExtraLight";
  src: url("../public/Jost/static/Jost-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Jost Thin";
  src: url("../public/Jost/static/Jost-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Bold";
  src: url("../public/Fira_Sans/FiraSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans";
  src: url("../public/Fira_Sans/FiraSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Light";
  src: url("../public/Fira_Sans/FiraSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans ExtraLight";
  src: url("../public/Fira_Sans/FiraSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Fira Sans Thin";
  src: url("../public/Fira_Sans/FiraSans-Thin.ttf") format("truetype");
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: "Jost";
  color: #140901;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

/* For Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
